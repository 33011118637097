enum Actions {
  // action types
  NEW_CERTIFICATE_ITEM = "incoming_certificate_NEW_CERTIFICATE_ITEM",
  EDIT_CERTIFICATE_ITEM = "incoming_certificate_EDIT_CERTIFICATE_ITEM",
  SEARCH = "incoming_certificate_SEARCH",
  SELECT_FIRST = "incoming_certificate_SELECT_FIRST",
  SELECT_ALL = "incoming_certificate_SELECT_ALL",
  LOAD_CERTIFICATES = "incoming_certificate_LOAD_CERTIFICATES",
  UNASSIGN_CERTIFICATE = "incoming_certificate_UNASSIGN_CERTIFICATE",
  ASSIGN_CERTIFICATE = "incoming_certificate_ASSIGN_CERTIFICATE",
  UNSELECT_ROW = "incoming_certificate_UNSELECT_ROW",
  UNSELECT_ALL = "incoming_certificate_UNSELECT_ALL",
  SELECT_SINGLE_ROW = "incoming_certificate_SELECT_SINGLE_ROW",
  SELECT_ROW = "incoming_certificate_SELECT_ROW",
  SET_FILTER = "incoming_certificate_SET_FILTER",
  SORT_COLUMN = "incoming_certificate_SORT_COLUMN",
  CHANGE_PAGE = "incoming_certificate_changePage",
  FETCH_DATA = "incoming_certificate_fetchData",
  CHANGE_DATA_TYPE = "incoming_certificate_changeDataType",
  MUTE = "incoming_certificate_mute",
  UNMUTE = "incoming_certificate_unmute",
}

enum Mutations {
  // mutation types
  SET_SEARCHED_CERTIFICATE_ITEMS = "incoming_certificate_SET_SEARCHED_CERTIFICATE_ITEMS",
  ADD_TO_FILTER = "incoming_certificate_ADD_TO_FILTER",
  SET_ASSIGNED_CERTIFICATE_ITEM_ID = "incoming_certificate_SET_ASSIGNED_CERTIFICATE_ITEM_ID",
  CLEAR_FILTER = "incoming_certificate_CLEAR_FILTER",
  ADD_ROW_TO_SELECT = "incoming_certificate_ADD_ROW_TO_SELECT",
  SET_SUGGESTED_CERTIFICATE_ITEMS = "incoming_certificate_SET_SUGGESTED_CERTIFICATE_ITEMS",
  SET_PAIRED_CERTIFICATE_ITEM = "incoming_certificate_SET_PAIRED_CERTIFICATE_ITEM",
  SELECT_ROW = "incoming_certificate_SELECT_ROW",
  SET_SORT_COLUMN = "incoming_certificate_SET_SORT_COLUMN",
  SET_CURRENT_PAGE = "incoming_certificate_SET_CURRENT_PAGE",
  SET_CHECKED_ROWS = "incoming_certificate_SET_CHECKED_ROWS",
  SET_FILTER = "incoming_certificate_setFilter",
  SET_TYPE = "incoming_certificate_setType",
  UPDATE_TABLE_KEY = "incoming_certificate_updateTableKey",
  SET_TOTAL = "incoming_certificate_setTotal",
  SET_DATA = "incoming_certificate_setData",
  SET_LOADING = "incoming_certificate_setLoading",
  SET_MY_RECORDS_ONLY = "incoming_certificate_setMyRecordsOnly",
}

export { Actions, Mutations };
