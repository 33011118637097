import { Action, Mutation, Module, VuexModule } from "vuex-module-decorators";
import { Actions, Mutations } from "@/store/modules/Search/Enums";
import ApiService from "@/core/services/ApiService";
import convertToCertificateItemFromApi from "@/store/modules/ConvertToCertificateItemFromApi";
import { CertificateItem } from "@/store/modules/CertificateItem";

@Module
export default class OutgoingCertificatesManagerModule extends VuexModule {
  searchedCertificateItems = [] as Array<CertificateItem>;
  @Mutation
  [Mutations.SET_SEARCHED_CERTIFICATE_ITEMS](items: Array<CertificateItem>) {
    this.searchedCertificateItems = items;
  }
  @Action
  [Actions.SEARCH](searchPhrase: string) {
    ApiService.setHeader();
    const url = new URL(
      ApiService.vueInstance.axios.defaults.baseURL + "/certificate/search"
    );
    url.searchParams.set("phrase", searchPhrase);
    return ApiService.get(url.toString()).then((response) => {
      this.context.commit(
        Mutations.SET_SEARCHED_CERTIFICATE_ITEMS,
        response.data.map((item) => convertToCertificateItemFromApi(item))
      );
    });
  }
}
