enum Actions {
  // action types
  NEW_CERTIFICATE_ITEM = "outgoing_certificate_NEW_CERTIFICATE_ITEM",
  EDIT_CERTIFICATE_ITEM = "outgoing_certificate_EDIT_CERTIFICATE_ITEM",
  RESEND_CERTIFICATE = "outgoingCertificatesManager/RESEND_CERTIFICATE",
  SELECT_FIRST = "outgoing_certificate_SELECT_FIRST",
  SELECT_ALL = "outgoing_certificate_SELECT_ALL",
  LOAD_CERTIFICATES = "outgoing_certificate_LOAD_CERTIFICATES",
  UNASSIGN_CERTIFICATE = "outgoing_certificate_UNASSIGN_CERTIFICATE",
  ASSIGN_CERTIFICATE = "outgoing_certificate_ASSIGN_CERTIFICATE",
  UNSELECT_ROW = "outgoing_certificate_UNSELECT_ROW",
  UNSELECT_ALL = "outgoing_certificate_UNSELECT_ALL",
  SELECT_SINGLE_ROW = "outgoing_certificate_SELECT_SINGLE_ROW",
  SELECT_ROW = "outgoing_certificate_SELECT_ROW",
  SET_FILTER = "outgoing_certificate_SET_FILTER",
  SORT_COLUMN = "outgoing_certificate_SORT_COLUMN",
  CHANGE_PAGE = "outgoing_certificate_changePage",
  FETCH_DATA = "outgoing_certificate_fetchData",
  CHANGE_DATA_TYPE = "outgoing_certificate_changeDataType",
  MUTE = "outgoing_certificate_mute",
  UNMUTE = "outgoing_certificate_unmute",
}

enum Mutations {
  // mutation types
  ADD_TO_FILTER = "outgoing_certificate_ADD_TO_FILTER",
  SET_ASSIGNED_CERTIFICATE_ITEM_ID = "outgoing_certificate_SET_ASSIGNED_CERTIFICATE_ITEM_ID",
  CLEAR_FILTER = "outgoing_certificate_CLEAR_FILTER",
  ADD_ROW_TO_SELECT = "outgoing_certificate_ADD_ROW_TO_SELECT",
  SET_SUGGESTED_CERTIFICATE_ITEMS = "outgoing_certificate_SET_SUGGESTED_CERTIFICATE_ITEMS",
  SET_PAIRED_CERTIFICATE_ITEM = "outgoing_certificate_SET_PAIRED_CERTIFICATE_ITEM",
  SELECT_ROW = "outgoing_certificate_SELECT_ROW",
  SET_SORT_COLUMN = "outgoing_certificate_SET_SORT_COLUMN",
  SET_CURRENT_PAGE = "outgoing_certificate_SET_CURRENT_PAGE",
  SET_CHECKED_ROWS = "outgoing_certificate_SET_CHECKED_ROWS",
  SET_FILTER = "outgoing_certificate_setFilter",
  SET_TYPE = "outgoing_certificate_setType",
  UPDATE_TABLE_KEY = "outgoing_certificate_updateTableKey",
  SET_TOTAL = "outgoing_certificate_setTotal",
  SET_DATA = "outgoing_certificate_setData",
  SET_LOADING = "outgoing_certificate_setLoading",
  SET_MY_RECORDS_ONLY = "outgoing_certificate_setMyRecordsOnly",
}

export { Actions, Mutations };
