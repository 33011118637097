import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { Actions, Mutations } from "./Enums";
import ApiService from "@/core/services/ApiService";
import convertToCertificateItemFromApi from "@/store/modules/ConvertToCertificateItemFromApi";
import { CertificateItem } from "@/store/modules/CertificateItem";
import { Filter, KtDatatable, SortColumn } from "@/store/modules/KtDatatable";

@Module
export default class IncomingCertificatesManagerModule extends VuexModule {
  myRecordsOnly = false as boolean;
  assignedCertificateItemId = 0 as number;
  assignedCertificateItem = null as null | CertificateItem;
  suggestedCertificateItems = [] as Array<CertificateItem>;
  searchedCertificateItems = [] as Array<CertificateItem>;
  table = {
    loading: false,
    currentPage: 1,
    rows: 0,
    rowsPerPage: 25,
    sortColumn: {
      columnName: "",
      order: "",
    } as SortColumn,
    type: "topair",
    filter: {},
    data: [],
    tableKey: 0,
  } as KtDatatable;
  selectedOrderItemsIds = [] as Array<number>;
  tableHeader = [
    {
      name: "",
      key: "id",
      filter: false,
      checkAll: true,
    },
    {
      name: "Type",
      key: "type",
    },
    {
      name: "Packing Nr.",
      key: "packing-list",
    },
    {
      name: "PO Nr.",
      key: "po-number",
    },
    {
      name: "GR Nr.",
      key: "gr-number",
    },
    {
      name: "GR Date",
      key: "gr-date",
      type: "date",
    },
    {
      name: "Article Nr.",
      key: "article-number",
    },
    {
      name: "Article",
      key: "article-description",
    },
    {
      name: "Batch Nr.",
      key: "batch-number",
    },
    {
      name: "Quantity",
      key: "batch-quantity",
    },
    {
      name: "Heat Nr.",
      key: "heatnumber",
    },
    {
      name: "Supplier Name",
      key: "supplier-name",
    },
  ];

  get selectedOrderItems() {
    return this.selectedOrderItemsIds;
  }

  @Mutation
  [Mutations.SET_LOADING](payload) {
    this.table.loading = payload;
  }
  @Mutation
  [Mutations.SET_DATA](payload) {
    this.table.data = payload;
  }
  @Mutation
  [Mutations.SET_TOTAL](payload) {
    this.table.rows = payload;
  }
  @Mutation
  [Mutations.UPDATE_TABLE_KEY]() {
    this.table.tableKey += 1;
  }
  @Mutation
  [Mutations.SET_TYPE](type: string) {
    this.table.type = type;
  }
  @Mutation
  [Mutations.ADD_TO_FILTER](filter: Filter) {
    this.table.filter[filter.name] = filter.value;
  }
  @Mutation
  [Mutations.SET_CHECKED_ROWS](ids: Array<number>) {
    this.selectedOrderItemsIds = ids;
    for (const item of this.table.data) {
      item.selected = null;
      for (const id of this.selectedOrderItemsIds) {
        if (item.id === id) {
          item.selected = true;
          break;
        }
        item.selected = false;
      }
      if (item.selected === null) {
        item.selected = false;
      }
    }
  }
  @Mutation
  [Mutations.SET_CURRENT_PAGE](page: number) {
    this.table.currentPage = page;
  }
  @Mutation
  [Mutations.SET_SORT_COLUMN](column: SortColumn) {
    this.table.sortColumn = column;
  }
  @Mutation
  [Mutations.ADD_ROW_TO_SELECT](id: number) {
    this.selectedOrderItemsIds.push(id);
    for (const item of this.table.data) {
      item.selected = null;
      for (const id of this.selectedOrderItemsIds) {
        if (item.id === id) {
          item.selected = true;
          break;
        }
        item.selected = false;
      }
      if (item.selected === null) {
        item.selected = false;
      }
    }
  }
  @Mutation
  [Mutations.SET_PAIRED_CERTIFICATE_ITEM](item: CertificateItem) {
    this.assignedCertificateItem = item;
    if (item === null) {
      this.assignedCertificateItemId = 0;
    } else {
      this.assignedCertificateItemId = item.certificateItemId;
    }
  }
  @Mutation
  [Mutations.SET_SUGGESTED_CERTIFICATE_ITEMS](items: Array<CertificateItem>) {
    this.suggestedCertificateItems = items;
  }
  @Mutation
  [Mutations.CLEAR_FILTER]() {
    const currentMyRecordsOnly = this.table.filter["my-records-only"];
    this.table.filter = [];
    this.table.filter["my-records-only"] = currentMyRecordsOnly;
  }
  @Mutation
  [Mutations.SET_ASSIGNED_CERTIFICATE_ITEM_ID](id: number) {
    this.assignedCertificateItemId = id;
  }
  @Mutation
  [Mutations.SET_SEARCHED_CERTIFICATE_ITEMS](items: Array<CertificateItem>) {
    this.searchedCertificateItems = items;
  }
  @Mutation
  [Mutations.SET_MY_RECORDS_ONLY](value: boolean) {
    this.myRecordsOnly = value;
    this.table.filter["my-records-only"] = value ? "1" : "0";
  }

  @Action
  [Actions.FETCH_DATA]() {
    const addFilterToParams = (searchParams, filter) => {
      Object.entries(filter).map((item) => {
        const [key, value] = item;
        searchParams.append("filter[]", key + "||" + value);
      });
    };

    this.context.commit(Mutations.SET_LOADING, true);

    const url = new URL(
      ApiService.vueInstance.axios.defaults.baseURL +
        "/order/purchase-orders-items"
    );
    url.searchParams.set("page", this.table.currentPage.toString());
    url.searchParams.set("rows", this.table.rowsPerPage.toString());
    url.searchParams.set("sortColumn", this.table.sortColumn.columnName);
    url.searchParams.set("sortOrder", this.table.sortColumn.order);
    url.searchParams.set("type", this.table.type);
    addFilterToParams(url.searchParams, this.table.filter);
    ApiService.setHeader();
    return Promise.all([
      this.context.dispatch(Actions.UNSELECT_ALL),
      ApiService.vueInstance.axios.get(url.toString()).then((response) => {
        this.context.commit(Mutations.SET_DATA, response.data.data);
        this.context.commit(Mutations.SET_TOTAL, response.data.total);
        this.context.commit(Mutations.UPDATE_TABLE_KEY);
        return this.context.dispatch(Actions.SELECT_FIRST).then(() => {
          this.context.commit(Mutations.SET_LOADING, false);
        });
      }),
    ]);
  }
  @Action
  [Actions.CHANGE_DATA_TYPE](type) {
    this.context.commit(Mutations.SET_TYPE, type);
    this.context.commit(Mutations.CLEAR_FILTER, {});
    return this.context.dispatch(Actions.CHANGE_PAGE, 1);
  }
  @Action
  [Actions.CHANGE_PAGE](page) {
    this.context.commit(Mutations.SET_CURRENT_PAGE, page);
    this.context.commit(Mutations.SET_CHECKED_ROWS, []);
    return this.context.dispatch(Actions.FETCH_DATA);
  }
  @Action
  [Actions.SORT_COLUMN](sortValue) {
    this.context.commit(Mutations.SET_CURRENT_PAGE, 1);
    this.context.commit(Mutations.SET_SORT_COLUMN, sortValue);
    return this.context.dispatch(Actions.FETCH_DATA);
  }
  @Action
  [Actions.SET_FILTER](filter: Filter) {
    this.context.commit(Mutations.SET_CURRENT_PAGE, 1);
    this.context.commit(Mutations.ADD_TO_FILTER, filter);
    return this.context.dispatch(Actions.FETCH_DATA);
  }
  @Action
  [Actions.SELECT_ROW](id: number) {
    this.context.commit(Mutations.ADD_ROW_TO_SELECT, id);
    return this.context.dispatch(Actions.LOAD_CERTIFICATES);
  }
  @Action
  [Actions.LOAD_CERTIFICATES]() {
    if (this.selectedOrderItemsIds.length === 1) {
      const id = this.selectedOrderItemsIds[0];
      const url = new URL(
        ApiService.vueInstance.axios.defaults.baseURL +
          "/certificate/certificates-items-by-order-item"
      );
      url.searchParams.set("id", id.toString());
      ApiService.setHeader();

      return ApiService.vueInstance.axios
        .get(url.toString())
        .then((response) => {
          let paired = null as null | CertificateItem;
          if (
            typeof response.data.paired === "object" &&
            !Array.isArray(response.data.paired)
          ) {
            paired = convertToCertificateItemFromApi(response.data.paired);
          }
          this.context.commit(Mutations.SET_PAIRED_CERTIFICATE_ITEM, paired);
          const suggested = response.data.suggested.map((item) =>
            convertToCertificateItemFromApi(item)
          );
          this.context.commit(
            Mutations.SET_SUGGESTED_CERTIFICATE_ITEMS,
            suggested
          );
        });
    } else {
      this.context.commit(Mutations.SET_PAIRED_CERTIFICATE_ITEM, null);
      this.context.commit(Mutations.SET_SUGGESTED_CERTIFICATE_ITEMS, []);
    }
  }
  @Action
  [Actions.SELECT_SINGLE_ROW](id: number) {
    this.context.commit(Mutations.SET_CHECKED_ROWS, [id]);
    return this.context.dispatch(Actions.LOAD_CERTIFICATES);
  }
  @Action
  [Actions.SELECT_FIRST]() {
    const ids = this.table.data.map((item) => item.id);
    if (ids.length > 0) {
      return this.context.dispatch(Actions.SELECT_SINGLE_ROW, ids[0]);
    } else {
      return Promise.all([]);
    }
  }
  @Action
  [Actions.SELECT_ALL]() {
    const ids = this.table.data.map((item) => item.id);
    this.context.commit(Mutations.SET_CHECKED_ROWS, ids);
    this.context.commit(Mutations.SET_PAIRED_CERTIFICATE_ITEM, null);
    this.context.commit(Mutations.SET_SUGGESTED_CERTIFICATE_ITEMS, []);
  }
  @Action
  [Actions.UNSELECT_ALL]() {
    this.context.commit(Mutations.SET_CHECKED_ROWS, []);
    this.context.commit(Mutations.SET_PAIRED_CERTIFICATE_ITEM, null);
    this.context.commit(Mutations.SET_SUGGESTED_CERTIFICATE_ITEMS, []);
  }
  @Action
  [Actions.UNSELECT_ROW](id) {
    const newlyChecked = this.selectedOrderItemsIds.filter(
      (item) => item !== id
    );
    if (newlyChecked.length === 0) {
      return this.context.dispatch(Actions.UNSELECT_ALL);
    }
    if (newlyChecked.length === 1) {
      return this.context.dispatch(Actions.SELECT_SINGLE_ROW, newlyChecked[0]);
    }
    this.context.commit(Mutations.SET_CHECKED_ROWS, newlyChecked);
    this.context.commit(Mutations.SET_PAIRED_CERTIFICATE_ITEM, null);
    this.context.commit(Mutations.SET_SUGGESTED_CERTIFICATE_ITEMS, []);
  }
  @Action
  [Actions.ASSIGN_CERTIFICATE](data) {
    ApiService.setHeader();
    this.selectedOrderItems;
    const id = data.certificateItemId;
    const force = data.force;
    const formData = new FormData();
    this.selectedOrderItemsIds.forEach((orderItemId) => {
      formData.append("order_item_ids[]", orderItemId.toString());
    });
    formData.append("force", (force ? 1 : 0).toString());
    formData.append("certificate_item_id", id.toString());
    return ApiService.post("/order/assign-certificate-item", formData).then(
      (data) => {
        if (data.data.error) {
          return { error: data.data.error, result: "error" };
        }
        if (data.data.warning) {
          return { warning: data.data.warning, result: "warning" };
        }
        this.context.commit(Mutations.SET_ASSIGNED_CERTIFICATE_ITEM_ID, id);
        return { error: null, result: "success" };
      }
    );
  }
  @Action
  [Actions.UNASSIGN_CERTIFICATE]() {
    ApiService.setHeader();
    const formData = new FormData();
    const orderItemId = this.selectedOrderItemsIds[0];
    formData.append("order_item_id", orderItemId.toString());
    ApiService.post("/order/unassign-certificate-item", formData).then(() => {
      this.context.commit(Mutations.SET_ASSIGNED_CERTIFICATE_ITEM_ID, 0);
    });
  }
  @Action
  [Actions.SEARCH](searchPhrase: string) {
    ApiService.setHeader();
    const url = new URL(
      ApiService.vueInstance.axios.defaults.baseURL + "/certificate/search"
    );
    url.searchParams.set("phrase", searchPhrase);
    return ApiService.get(url.toString()).then((response) => {
      this.context.commit(
        Mutations.SET_SEARCHED_CERTIFICATE_ITEMS,
        response.data.map((item) => convertToCertificateItemFromApi(item))
      );
    });
  }

  @Action
  [Actions.MUTE]() {
    ApiService.setHeader();
    const formData = new FormData();
    const orderItemIds = this.selectedOrderItemsIds;
    formData.append("order_item_ids", JSON.stringify(orderItemIds));
    ApiService.post("/order/mute", formData).then(() => {
      return this.context.dispatch(Actions.FETCH_DATA);
    });
  }

  @Action
  [Actions.UNMUTE]() {
    ApiService.setHeader();
    const formData = new FormData();
    const orderItemIds = this.selectedOrderItemsIds;
    formData.append("order_item_ids", JSON.stringify(orderItemIds));
    ApiService.post("/order/unmute", formData).then(() => {
      return this.context.dispatch(Actions.FETCH_DATA);
    });
  }

  @Action
  [Actions.NEW_CERTIFICATE_ITEM](data) {
    ApiService.setHeader();
    const formData = new FormData();
    formData.append("orderItemIds", data.orderItemIds);
    formData.append("certificateItemId", data.certificateItemId);
    return ApiService.post("/certificate/new-certificate-item", formData);
  }

  @Action
  [Actions.EDIT_CERTIFICATE_ITEM](data) {
    ApiService.setHeader();
    const formData = new FormData();
    formData.append("certificateItemId", data.certificateItemId);
    formData.append("packingList", data.packingList);
    formData.append("purchaseOrderNumber", data.purchaseOrderNumber);
    formData.append("heatnumber", data.heatnumber);
    return ApiService.post("/certificate/edit-certificate-item", formData).then(
      () => {
        return this.context.dispatch(Actions.LOAD_CERTIFICATES);
      }
    );
  }
}
