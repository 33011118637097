enum Actions {
  // action types
  SEARCH = "SEARCH",
}

enum Mutations {
  // mutation types
  SET_SEARCHED_CERTIFICATE_ITEMS = "SET_SEARCHED_CERTIFICATE_ITEMS",
}

export { Actions, Mutations };
